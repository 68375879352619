.caw-foldout {
  &__trigger {
    @include icomoon(icon-chevron-down, after, 1rem);
    border-bottom: .1rem solid $border-color;
    border-top: .1rem solid $border-color;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: $font-bold;
    padding: $block-padding 0;
    position: relative;

    &:after {
      line-height: inherit;
      position: absolute;
      top: $block-padding;
      right: 0;
      transition: transform .3s ease;
    }
  }

  &__content {
    padding: $block-padding 0;
  }

  &--large {
    border-bottom: 1px solid $border-color;

    @include bp-up(lg){
      padding: 2.5rem 0;
    }

    .caw-foldout__trigger {
      border: 0;
      font-size: 2.4rem;
      font-weight: $font-bold;

      @include bp-up(lg){
        font-size: 3.2rem;
        line-height: 3.8rem;
      }

      &:after {
        font-size: 1.6rem;
        top: 1.6rem;
        right: 0;
      }
    }
  }

  &--open {
    .caw-foldout__trigger:after {
      transform: rotate(180deg);
    }
  }
}

.caw-foldout[data-dealer-id]:not([data-dealer-id=""]) {
  .caw-foldout__trigger {
    border-bottom: 0;
  }

  .caw-foldout__content {
    padding-top: 0;
  }
}
