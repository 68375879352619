.caw-mobilemenu {
  @extend %reset-list;
  position: fixed;
  height: $mobilemenu-height;
  margin: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1001;

  .item-list {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    flex-grow: 1;
    text-align: center;

    &__link {
      background-color: $color-secondary;
      display: block;
      border-right: 1px solid $white;
      color: $white;
      text-decoration: none;
      transition: background-color .3s ease-out;
      font-size: 1.3rem;
      padding: 1rem 0 0 0;

      height: 100%;
      min-height: $mobilemenu-height;
      width: 100%;

      &:hover {
        background-color: darken($color-secondary, 8%);
      }

      &:before {
        display: block;
        height: 2.4rem;
        font-size: 2.4rem;
        margin: 0 auto;
      }

      &.whatsapp {
        background-color: $whatsapp;
        border-right: 1px solid $white;
        color: $white;

        &:hover {
          background-color: $whatsapp;
        }
      }
    }

    &:last-of-type .item__link {
      border-right: 0;
    }

    &--more {

      .item__link {
        background-color: $grey-light;
        color: $black;

        &:hover {
          background-color: darken($grey-light, 5%);
        }
      }
    }
  }

  @include bp-up(lg) {
    display: none;
  }
}

.caw-mobilemenu-sub {
  display: block;
  position: fixed;
  margin: 0;
  left: 0;
  right: 0;
  bottom: -100%;
  z-index: 1001;
  transition: all .3s ease-out;
  opacity: 0;

  .close {
    cursor: pointer;
    position: absolute;
    right: $gutter/2;
    top: $gutter/2;

    &:before {
      font-size: 2.4rem;
    }
  }

  &:after {
    background-color: rgba($grey-dark, 0);
    width: 100%;
    height: 100%;
    content: '';
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    z-index: -1;
    transition: background-color .3s ease-out;
  }

  &--open {
    bottom: -1px;
    opacity: 1;

    &:after {
      background-color: rgba($grey-dark, .8);
      bottom: 0;
    }
  }

  .item-list {
    background-color: $grey-light;
    padding: $gutter;
    flex-direction: column;
  }

  .item {
    border-top: 1px solid $grey-medium;
    text-align: left;
    padding: $gutter/2 0;

    &__link {
      background-color: transparent;
      border-right: 0;
      color: $black;
      font-size: 1.8rem;
      padding: 0;
      min-height: 0;

      &:hover {
        background-color: transparent;
      }

      &:before {
        display: inline-block;
        font-size: 1.6rem;
        width: 1.7rem;
        margin-right: $gutter/2;
      }
    }

    &:first-child {
      border-top: 0;
    }
  }
}

.gallery--open .caw-mobilemenu {
  z-index: 1;
}
