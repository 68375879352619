.tab__documents {
  &__single {
    background-color: $blue-lightest;
    margin-bottom: 15px;
    a {
      color: $blue-dark;
      padding: 8px;
      text-decoration: underline;
      border: transparent;
      transition: none;
    }
  }
}