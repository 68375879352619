p {
  font-weight: $font-light;
  line-height: 1.7em;
  margin: 1em 0;
}

h1,
p.vehicles-count {
  font-weight: $font-bold;
  font-size: 3.2rem;
  margin: $gutter/2 0 $gutter/2 0;
}

h3 {
  &.related {
    font-weight: $font-bold;
    font-size: 3.2rem;
    margin: $gutter/2 0 $gutter 0;
  }
}

a {
  color: $blue-other;
  display: inline-block;
  @include underlined($blue-other);
}
