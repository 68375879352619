.horizontal-menu {
  border: 1px solid $blue;
  border-radius: $default-border-radius;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  overflow: hidden;
  height: max-content;
  margin: 3rem 0;
  width: 100%;

  @include bp-up(lg){
    margin: .8rem 0;
    flex-wrap: wrap;
    width: auto;
    width: max-content;
  }

  &__item {
    border-right: 1px solid $blue;
    color: $blue;
    cursor: pointer;
    transition: background-color .2s;
    padding: 0 2rem;
    line-height: 4rem;
    text-align: left;
    flex: 1;

    @include bp-up(lg){
      line-height: 3.4rem;
      padding: 0 1.6rem;
      text-align: center;
      width: auto;
      flex: 0 auto;
    }

    &--active,
    &:hover {
      background: $blue;
      color: white;
    }

    &:last-of-type {
      border-right: 0px;
    }
  }

}