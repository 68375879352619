.header {
  .row {
    background-color: $blue-lightest;
  }

  .row:not(:first-child) {
    max-width: calc(100% + #{$gutter});
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  [class*="col-"] + [class*=" col-"] {
    order: -1;
  }

  form {
    margin: 0;
    margin-bottom: 1.6rem;
  }

  .header__top label.filter__label {
    font-size: 1.6rem;
    font-weight: $font-bold;
    position: relative;
    margin-bottom: $default-padding;
    display: block;
  }

  .header__top.is-hidden {
    display: none !important;
  }
  .select-wrapper {
    width: auto;

    > select {
      width: 100%;
      border-color: $border-color;
      background-color: $white;
      box-shadow: none;

      &:focus,
      &.form-control:focus {
        border-color: $border-color;
        box-shadow: none;
      }
    }
  }

  .l-search-wrapper {
    .filter-keywords:not(input) {
      /*@include icomoon(icon-search, after);*/

      position: relative;

      &:after {
        position: absolute;
        right: 1rem;
        bottom: 1.1rem;
      }
    }

    input {
      font-style: italic;
      padding-right: 3rem;

      &::placeholder {
        color: rgba($grey-medium, 0.5);
      }
    }
  }

  @include bp-up(lg) {
    .row {
      background-color: inherit;
    }

    &__top {
      display: flex;
      flex-wrap: wrap;

      & > .filters {
        display: flex;
      }
    }

    &__bottom {
      display: flex;
    }

    .row:not(:first-child) {
      padding-top: $default-padding;

      display: flex;

      &:after {
        width: 100vw;
        height: 1px;

        background-color: $grey-light;

        position: relative;
        left: 50%;

        transform: translate3d(-50%, 0, 0);

        content: "";
      }
    }

    [class*="col-"] + [class*=" col-"] {
      order: unset;
    }

    label.filter__label {
      font-size: inherit;
      font-weight: normal;

      margin: 0 1rem 0 0;

      display: inline-block;
    }

    .select-wrapper {
      width: fit-content;

      display: inline-block;
    }

    .l-search-wrapper {
      padding: 0;

      display: block;

      label,
      label.filter__label {
        display: none;
      }

      input[type="text"] {
        height: 3.3rem;
      }
    }
  }
}

.mobile--filter--open .header {
  .row:not(:first-child) {
    height: auto;
    opacity: 1;
  }
}

@include bp-up(lg) {
  .header,
  .mobile--filter--open .header {
    .row:not(:first-child) {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
}
