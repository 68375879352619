.photo {
  @include icomoon(icon-photo, before);
}

.video {
  @include icomoon(icon-play-circle-o, before);
}

.media360, .undo {
  @include icomoon(icon-undo, before);
}

.share {
  @include icomoon(icon-share-alt, before);
}

.favorite {
  @include icomoon(icon-heart, before);
}

.phone {
  @include icomoon(icon-phone, before);
}

.email {
  @include icomoon(icon-envelope, before);
}

.whatsapp {
  @include icomoon(icon-whatsapp, before);
}

.more {
  @include icomoon(icon-more-vertical, before);
}

.testdrive,
.trade {
  @include icomoon(icon-steering-wheel, before);
}

.close {
  @include icomoon(icon-close, before);
}
.trash {
  @include icomoon(icon-trash, before);
}

.file {
  @include icomoon(icon-file-o, before);
}

.download {
  @include icomoon(icon-download, before);
}

.eye {
  @include icomoon(icon-eye, before);
}
.map {
  @include icomoon(icon-map-marker, before);
}

.icon-caret-down {
  pointer-events: none;
  @include icomoon(icon-caret-down, after);
}

.icon-check {
  @include icomoon(icon-check);
}

.icon-filter {
  @include icomoon(icon-filter);
}

.icon-sort-amount-desc {
  @include icomoon(icon-sort-amount-desc);
}

.icon-sort-amount-asc {
  @include icomoon(icon-sort-amount-asc);
}

.icon-calendar {
  @include icomoon(icon-calendar);
}

.icon-brandstof {
  @include icomoon(icon-brandstof);
}

.icon-elektrisch {
  @include icomoon(icon-elektrisch);
}

.icon-transmissie {
  @include icomoon(icon-transmissie);
}

.icon-speedometer {
  @include icomoon(icon-speedometer);
}


.icon--large {
  max-width: 3rem;
  &:after,
  &:before {
    margin: 0;
    font-size: 2.8rem;
  }
}