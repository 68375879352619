// RESET MIXINS.

// CAW4 Resets mixin.
@mixin caw4-css-resets() {
  //all: unset;

  width: initial;
  min-width: initial;
  max-width: initial;

  height: initial;
  min-height: initial;
  max-height: initial;

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
  line-height: inherit;
  letter-spacing: inherit;

  border: initial;

  background: initial;
  background-color: initial;
  background-image: initial;

  padding: initial;
  margin: initial;

  //display: initial;

  position: initial;
  top: initial;
  left: initial;
  right: initial;
  bottom: initial;
}

// BASIC RESETS.
* {
  box-sizing: border-box;

  &:before,
  &:after {
    width: unset;
    height: unset;

    box-sizing: inherit;

    content: unset;
  }
}

// FONT RESETS.
* {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

// ADDITIONAL RESETS.

// Content sectioning.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Content_sectioning
address,
article,
aside,
footer,
header,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
main,
nav,
section {
  @include caw4-css-resets();
}

// Text content.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Text_content
blockquote,
dd,
div,
dl,
dt,
figcaption,
figure,
hr,
li,
main,
ol,
p,
pre,
ul {
  @include caw4-css-resets();
}

// Inline text semantics.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Inline_text_semantics
a,
abbr,
b,
bdi,
bdo,
br,
cite,
code,
data,
dfn,
em,
i,
kbd,
mark,
q,
rb,
rp,
rt,
rtc,
ruby,
s,
samp,
small,
span,
strong,
sub,
sup,
time,
u,
var,
wbr {
  @include caw4-css-resets();
}

// Image and multimedia.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Image_and_multimedia
area,
audio,
img,
map,
track,
video {
  @include caw4-css-resets();
}

// Embedded content.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Embedded_content
/*embed,
iframe,
object,
param,
picture,
source {
  @include caw4-css-resets();
}*/

// Table content.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Table_content
caption,
col,
colgroup,
table,
tbody,
td,
tfoot,
th,
thead,
tr {
  @include caw4-css-resets();
}

// Forms.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element#Forms
button,
datalist,
fieldset,
form,
input,
label,
legend,
meter,
optgroup,
option,
output,
progress,
select,
textarea {
  @include caw4-css-resets();
}

// Additional 'display' resets.
html,
address,
blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
frame,
frameset,
h1,
h2,
h3,
h4,
h5,
h6,
noframes,
ol,
p,
ul,
center,
dir,
hr,
menu,
pre {
  display: block;
}

li {
  display: list-item;
}

head {
  display: none;
}

table {
  display: table;
}

tr {
  display: table-row;
}

thead {
  display: table-header-group;
}

tbody {
  display: table-row-group;
}

tfoot {
  display: table-footer-group;
}

col {
  display: table-column;
}
colgroup {
  display: table-column-group;
}

td,
th {
  display: table-cell;
}

caption {
  display: table-caption;
}

input,
select {
  display: inline-block;
}

// OTHER CUSTOM RESETS.
img {
  max-width: 100%;
}

.card--left {
  flex-direction: row;
}

.card--location .card__content {
  flex-direction: row;
}

.container .row,
.container-fluid .row {
  display: flex !important;
  grid-gap: 0;
}

form {
  label {
    font-family: inherit !important;
    float: none;
  }

  button[type="submit"] {
    &:after {
      @include caw4-css-resets();
    }
  }
}
