@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner.loader {
  &::before {
    position: absolute;
    top: 120px;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    content: "";
    box-sizing: border-box;

    width: 30px;
    height: 30px;

    border-radius: 50%;
    border-top: 3px solid #266dd1;
    border-right: 3px solid transparent;
    animation: spinner 0.6s linear infinite;
  }
}
