%reset-list {
  padding-left: 0;

  li {
    list-style: none;
  }
}

@mixin bp-up($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin bp-down($breakpoint) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    $mw: map-get($breakpoints, $breakpoint) - 1px;

    // Prints a media query based on the value
    @media (max-width: $mw) {
      @content;
    }
  }
    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from #{$breakpoint}. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin caw-padding() {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include bp-up(sm) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@mixin caw-container() {
  width: 100%;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin underlined($color: $color-primary) {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  transition: border-color .3s ease-out;
  &:hover {
    border-bottom-color: $color;
    text-decoration: none;
  }
}

@mixin breakout($color: $white, $parentwidth: 100%) {
  background: $color;
  content: "";
  bottom: 0;
  height: 100%;
  width: 100vw;
  left: calc(-1 * (100vw - #{$parentwidth} ) / 2);
  position: absolute;
  z-index: -1;
}