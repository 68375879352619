// All styling gets wrapped on compile.
// Styling gets wrapped by the '#app-wrapper.caw' selector.

// Additional resets.
* {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

::selection {
  color: #ffffff !important;
  background: #266dd1;
}

// CAW default styling.
.caw,
.caw-mini {
  @extend html !optional;
  @extend body !optional;

  color: $grey-dark;
  font-size: $base-fontsize;
  line-height: $base-lineheight;
  font-family: "Roboto", sans-serif;

  overflow: hidden;

  position: relative;
  z-index: 1;

  b,
  strong {
    font-weight: $font-bold;
  }
}

// @Todo: move this to the appropriate file. (app.scss?)
  #caw-container.caw {
    ::selection {
      color: #ffffff !important;
      background: #266dd1;
    }
  }

// @Todo: move this to the appropriate file.
  html.is-ios.is-safari & #caw-container.caw {
    overflow: visible;

    @include bp-up(lg) {
      overflow: hidden;
    }
  }
