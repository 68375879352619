.card {
  border-top: 1px solid $border-color;
  padding: $gutter/2 0;
  font-size: 1.6rem;
  font-weight: $font-light;
  line-height: 1.9rem;

  &__title {
    font-size: inherit;
    font-weight: $font-regular;
    margin: .6rem 0 0 0;

    &:first-child {
      font-size: 1.6rem;
      font-weight: $font-bold;
      margin: 0 0 $gutter/2 0;
    }
  }

  &__description {
    .phone {
      margin-top: .6rem;
    }
  }

  &__action {
    //margin-top: 1.8rem;
    margin-top: 1.6rem;
  }

  &--left {
    display: flex;

    .card__media {
      width: 8rem;
    }

    .card__content {
      padding-left: $block-padding;
    }
  }

  &--contact {
    .card__title {
      &:first-child {
        margin: 0;
      }
    }

    .card__image {
      //border-radius: 50%;
      height: 8rem;
      width: 8rem;
    }

    .phone {
      @include underlined($color-secondary);
      color: $color-secondary;
      text-decoration: none;

      &:before {
        font-size: inherit;
        
        margin-top: .2rem;
        margin-left: .5rem;

        float: left;
      }
    }
  }

  &--location {
    .card__media + .card__content {
      margin-top: $gutter/2;
    }

    .card__content {
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
    }

    .card__action {
      margin-top: 0;
      vertical-align: top;
    }

    .map {
      color: $blue-other;
    }
  }
}

.caw-foldout .card {
  border: 0;
  padding: 0;
}
