.table {
  width: 100%;

  th {
    font-weight: $font-light;
  }

  th,
  td {
    height: 2em;
    text-align: left;
    width: 50%;
  }
}

.table--options,
table.table--options,
.table--specifications,
table.table--specifications {
  margin: 0;

  tr {
    border: none;
  }

  th,
  td {
    height: auto;
    padding: .5rem .5rem .5rem 0;
  }

  td {
    font-weight: $font-bold;
  }
}

.table--options,
table.table--options {
  td {
    font-weight: inherit;
  }
}

.table--electric {
  column-count: 1;
  @include bp-up(lg) {
    column-count: 2;
  }
  width: 100%;
  display: inline-block;
}