// Global gallery styling.
.slick-slider {
  position: relative;
}

.slick-slide {
  height: auto;
}

.slick-prev,
.slick-next,
.slick-paging,
.slick-close {
  width: 3rem;
  height: 3rem;

  text-align: center;
  line-height: 3rem;

  border: 0;

  background: transparent;

  padding: 0;
  margin: 0;

  position: absolute;
  top: calc(50% - 3rem);
  z-index: 99999;

  opacity: 1;

  cursor: pointer;

  &:before {
    margin: 0 !important;
    transform: none;
  }
}

.slick-prev,
.slick-next {
  font-size: 0;
  color: transparent;
  text-indent: 0;

  overflow: hidden;

  &:before {
    color: $white;
    line-height: inherit !important;
  }
}

.slick-prev {
  @include icomoon(icon-chevron-left, before, 2rem);

  left: 0;
}

.slick-next {
  @include icomoon(icon-chevron-right, before, 2rem);

  right: 0;
}

.slick-paging {
  @include icomoon(icon-photo, before);

  width: auto;

  border-radius: $default-border-radius;

  background-color: $white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);

  padding: 0 1rem;

  display: none;

  right: - $default-border-radius;

  &.hide {
    display: none;
  }

  @include bp-up(lg) {
    display: block;
  }
}

.slick-close {
  @include icomoon('icon-close');

  display: none;

  top: ($gutter / 2);
  right: ($gutter / 2);

  &:before {
    font-size: 2.5rem;
    color: $white;
    line-height: inherit !important;
  }

  &.show {
    display: block;
  }
}

.slick-dots {
  text-align: center;

  padding: 1rem ($gutter / 2);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;

  li {
    width: .8rem;
    height: .8rem;

    border-radius: 50%;
    border: none;

    background-color: $white;
    box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, .8);

    margin: .4rem .4rem 0 .4rem;

    flex-grow: 0;

    visibility: visible;

    opacity: .6;

    cursor: pointer;

    transition: opacity .3s ease-in-out 0s;
  }

  li.slick-active {
    opacity: 1;
  }

  button {
    display: none;
  }
}

// Teaser gallery styling.
.teaser {
  .caw-teaser__gallery {
    .slick-prev,
    .slick-next {
      opacity: 0;

      transition: opacity .3s ease-in-out 0s;
    }

    // Show/hide buttons depending on screen size.
    // Override the inline styles.
    .slick-prev,
    .slick-next {
      @media (hover: hover) and (pointer: fine) {
        display: block !important;
      }
    }

    .slick-dots {
      display: none !important;
    }
  }

  &:hover .caw-teaser__gallery {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }
}

// Teaser gallery styling overrides.
// Override any color theme styling here...
#caw-container.caw .caw-teaser__gallery.slick-slider {
  button {
    &,
    &:before {
      color: $grey-dark;
    }

    background-color: rgba($white, .8);
    box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, .8);
  }
}

// Detail gallery styling.
.caw-detail__header {
  overflow: hidden;
  position: relative;

  .slick-prev,
  .slick-next,
  .slick-close {
    width: ($gutter * 1.5);
    height: ($gutter * 1.5);

    text-align: center;
    line-height: ($gutter * 1.5);

    top: calc(50% - (#{$gutter} * 1.5 / 2));

    &:before {
      display: inline-block;

      transition: transform .3s ease 0s;
    }
  }

  .slick-prev {
    left: ($gutter / 2);

    &:hover:before {
      transform: translate3d(-.5rem, 0, 0) scale(1.2);
    }
  }

  .slick-next {
    right: ($gutter / 2);

    &:hover:before {
      transform: translate3d(.5rem, 0, 0) scale(1.2);
    }
  }

  .slick-close {
    top: $gutter;

    &:hover:before {
      transform: scale(1.2);
    }
  }
}

.caw-detail__gallery {
  width: 100%;
  height: auto;
  max-height: 65rem;

  overflow: hidden;
  position: relative;

  cursor: pointer;

  &:not(.slick-initialized) {
    .slick-slide:not(:first-child) {
      opacity: 0;
    }
  }

  &:not(.open) {
    background-color: $detail-image-background;

    .slick-list {
      height: auto !important;
      max-height: inherit;

      .slick-track {
        max-height: inherit;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
      }
    }

    .slick-slide {
      > * {
        pointer-events: none;
      }

      img {
        height: auto;
      }

      @include bp-up('md') {
        img {
          width: auto;
          //height: 100%;

          object-fit: contain;
        }
      }

      iframe {
        height: 100%;
      }
    }

    .slick-prev,
    .slick-next,
    .slick-close {
      background-color: transparent !important;
    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }

    @include bp-up('md') {
      .slick-prev,
      .slick-next {
        display: block !important;
      }
    }
  }

  .slick-slide {
    margin: 0;

    &,
    &:focus {
      outline: none;
    }

    // @todo fix opacity fade anim (does not work well with cloned items).

    // Inactive.
    &,
    &[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
      opacity: .6;

      transition: all .3s linear 0s;
    }

    // Active.
    &.slick-center,
    &[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
      opacity: 1;
    }
  }

  img,
  iframe {
    width: 100%;
    height: auto;
    max-height: 65rem;
    display: block;
  }

  img {
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  iframe {
    border: none;
  }

  iframe.photomotion-iframe,
  iframe.photorobot-iframe,
  iframe[src*="photo-motion.com"],
  iframe[src*="hosting.photorobot.com"],
  iframe.spincar-iframe,
  iframe[src*="spins.spincar.com"] {
    height: calc(100vw / (4 / 3));

    html:not(.is-touch) & {
      pointer-events: none;
    }

    @include bp-up(lg) {
      height: 75rem;
    }
  }
}

.caw-detail__gallery__thumbs {
  height: 12rem;

  margin-top: 2rem;
  margin-bottom: 2rem;

  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      margin-bottom: 12rem;

      @include bp-up(lg) {
        margin-bottom: 2rem;
      }
    }
  }

  display: none;

  img {
    max-width: 100%;
    max-height: 100%;

    margin: auto;

    &:focus {
      outline: none;
    }
  }

  &.show {
    display: block;
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    align-content: stretch;
  }

  &.slick-no-slide .slick-track {
    width: 100% !important;

    transform: none !important;
  }

  .slick-slide {
    height: 12rem;

    border: .3rem solid transparent;

    background-color: rgba(255, 255, 255, .2);

    margin: 0 .25rem;

    display: flex;

    opacity: .8;

    transition: all .3s ease;

    cursor: pointer;

    box-sizing: border-box;

    overflow: hidden;

    &:hover,
    &.slick-current {
      border-color: $blue;

      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}

// CSS for opening & closing gallery.
.gallery--open,
&.gallery--open {
  max-height: none;
  overflow: visible;
  position: relative;
  z-index: 999999;

  .caw-detail__header {
    width: 100vw;
    height: 100vh;

    background-color: $black;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  .caw-detail__gallery {
    margin-top: 0;
    margin-bottom: auto;

    cursor: default;

    &:after {
      content: none;
    }

    img,
    iframe,
    object {
      height: 100%;
    }

    img {
      object-fit: contain;

      touch-action: pinch-zoom;
    }

    iframe.photomotion-iframe,
    iframe.photorobot-iframe,
    iframe[src*="photo-motion.com"],
    iframe[src*="hosting.photorobot.com"] {
      width: calc(100vh + 16rem);
      max-width: 100%;

      html.is-touch &,
      html:not(.is-touch) & {
        pointer-events: auto;
      }
    }

    iframe,
    object {
      z-index: -1;
    }

    iframe.taggle-video,
    iframe.youtube-video {
      z-index: 1;
    }

    iframe.youtube-video {
      max-width: 100%;

      /*@include bp-up(lg) {
        width: calc(100% - 12rem); // Leave some space for gallery buttons.
      }*/
    }

    .slick-list {
      height: 100% !important; // Prevent issues with fullscreen toggle.

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;

      //z-index: -1;
    }

    .slick-track {
      height: auto;
      max-height: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;
    }

    .slick-slide {
      min-width: 100vw;
      height: 100%;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: stretch;

      outline: 0;
    }

    .slick-prev,
    .slick-next {
      display: block !important;
    }

    .slick-prev {
      left: ($gutter / 2);
    }

    .slick-next {
      right: ($gutter / 2);
    }
  }
}

.gallery--open {
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      position: static;
    }
  }
}

// CSS for opening & closing gallery.
// Portrait vs landscape, small and large screens.
.gallery--open,
&.gallery--open {
  .caw-detail__gallery__thumbs,
  .caw-detail__gallery__thumbs.show {
    display: none !important;
  }
}

@media only screen and(min-width: map-get($breakpoints, 'md')) and(min-height: map-get($breakpoints, 'sm')),
(max-width: map-get($breakpoints, 'md')) and(orientation: portrait) {
  .gallery--open {
    .caw-detail__header {
      > .slick-slider {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  .gallery--open,
  &.gallery--open {
    .caw-detail__gallery {
      max-height: calc(100vh - 16rem); // 100% - thumb height

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      img,
      iframe,
      object {
        max-height: calc(100vh - 16rem);
      }

      iframe,
      iframe.photomotion-iframe,
      iframe.photorobot-iframe,
      iframe[src*="photo-motion.com"],
      iframe[src*="hosting.photorobot.com"] {
        height: calc(100vh - 16rem);
      }
    }

    .caw-detail__gallery__thumbs,
    .caw-detail__gallery__thumbs.show {
      display: block !important;
    }
  }

}

/* Safari! */
html.is-mobile.is-ios &,
html.is-mobile.is-safari & {

  .gallery--open,
  &.gallery--open {

    .caw-detail__gallery {
      //max-height: calc(100vh - 32rem); // 100% - thumb height
      max-height: 100vh;

      @include bp-up(lg) {
        max-height: calc(100vh - 16rem);
      }

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      img,
      iframe,
      object {
        //max-height: calc(100vh - 32rem);
        max-height: 100vh;

        @include bp-up(lg) {
          max-height: calc(100vh - 16rem);
        }
      }

      iframe,
      iframe.photomotion-iframe,
      iframe.photorobot-iframe,
      iframe[src*="photo-motion.com"],
      iframe[src*="hosting.photorobot.com"] {
        //height: calc(100vh - 32rem);
        max-height: 100vh;

        @include bp-up(lg) {
          height: calc(100vh - 16rem);
        }
      }
    }

    .caw-detail__gallery__thumbs,
    .caw-detail__gallery__thumbs.show {
      margin-bottom: 16rem;

      @include bp-up(md) {
        margin-bottom: auto;
      }
    }

  }

}

html.is-safari & {
  &.gallery--open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .caw-detail__gallery .slick-list {
    padding: 0 !important;
  }
}

.filter--open .caw-teaser__gallery {
  z-index: 0;
}

// 360 player styling.
// Show 360 players on top of detail gallery.
// Hide 360 player on detail gallery full view.
// @see UAW-677 / UAW-736 / UAW-739.

// 360 player wrapper (keep this in line with the CAW detail gallery wrapper structure).
.caw-detail__360player {
  @extend .caw-detail__gallery;
}

.caw-detail__360player + .caw-detail__gallery:not(.open) {
  display: none !important;
}

.gallery--open .caw-detail__360player {
  display: none !important;
}

// Photomotion styling.
// @see AV-846
.spinner--toggle {
  user-select: none;

  &:after {
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, .4);

    position: absolute;
    top: 0;
    left: 0;

    transition: all .5s ease 0s;

    content: "";
  }

  .pmb--open,
  .pmb--close {
    font-size: 1.6rem;
    color: $white;
    text-align: center;

    position: absolute;
    z-index: 1;
  }

  .pmb--open {
    width: auto;
    height: auto;

    background-image: url('../img/photomotion/360-icon.png');
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: auto 124px;

    padding-top: 139px;

    top: calc(50% + 20px);
    left: 50%;

    transform: translate3d(-50%, -50%, 0);
    transition: all .5s ease 0s, visibility .01ms linear 0s;
  }

  .pmb--close {
    @include icomoon(icon-close, before, 2rem);

    width: ($gutter * 1.5);
    height: ($gutter * 1.5);

    font-size: 0;
    color: transparent;

    border-radius: 8px;

    background-color: rgba(0, 0, 0, .5);

    position: absolute;
    top: 1rem;
    right: 1rem;

    opacity: 0;
    visibility: hidden;

    transition: all .5s ease 0s, visibility .01ms linear .5s;

    &:before {
      color: $white;

      position: absolute;
      top: 1.2rem;
      left: 1.5rem;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }
}

.spinner--toggle.active {
  &:after {
    opacity: 0;

    pointer-events: none;
  }

  .pmb--open {
    padding-top: 119px;
    padding-bottom: 20px;

    opacity: 0;
    visibility: hidden;

    transition: all .5s ease 0s, visibility .01ms linear .5s;
  }

  .pmb--close {
    opacity: 1;
    visibility: visible;

    transition: all .5s ease 0s, visibility .01ms linear 0s;
  }
}

.spinner--photorobot {
  iframe.photorobot-iframe {
    border: none;
    overflow: hidden;
  }
}

// Visibility.
.spinner--photomotion,
.spinner--photorobot {
  &:after,
  > *:not(iframe) {
    display: none;
  }

  iframe.photomotion-iframe,
  iframe.photorobot-iframe,
  iframe[src*="hosting.photorobot.com"],
  iframe[src*="photo-motion.com"] {
    html.is-touch &,
    html:not(.is-touch) & {
      pointer-events: initial;
    }
  }

  @include bp-up(lg) {
    &:after,
    > *:not(iframe) {
      display: block;
    }
  }
}

// Vivition styling.
// @see AV-1121
.spinner.spinner--vivition {
  height: 100%;
  max-height: inherit;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .vivition-player {
    width: 100%;
    height: 65rem;
    max-height: calc(100% - 10rem);
  }

  .vivition-thumbbar {
    width: 100%;
    height: 10rem;
  }
}

// When spinner is loaded dynamically with a trigger
// some issues occur. So we fix them here.
.caw-detail__spinner--toggled {
  .spinner--photomotion::after,
  .pmb.pmb--open,
  .pmb.pmb--close {
    display: none;
  }
}

.caw-detail__360player.caw-detail__spinner:has(.spinner.spinner--vivition) {
  max-height: 32rem;

  @include bp-up(md) {
    max-height: 54rem;
  }

  @include bp-up(lg) {
    max-height: 75rem;
  }
}
