// All reusable overviews

.overview {
  @extend %reset-list;

  &--grid {
    min-width: 100%;

    @include bp-up(lg){
      display: flex;
      flex-wrap: wrap;
      margin-left: -$gutter / 2;
      margin-right: -$gutter / 2;
    }
  }

  &--col-flexible {
    .overview__item {
      @include bp-up(lg){
        min-width: calc(25% - #{$gutter});
        max-width: calc(33.33% - #{$gutter});
      }
    }
  }

  &--col-3 {
    .overview__item {
      @include bp-up(lg){
        width: calc(33.33% - #{$gutter});
      }
    }
  }

  &--col-4 {
    .overview__item {
      @include bp-up(lg){
        width: calc(25% - #{$gutter});
      }
    }
  }
}

.aside--left:not(.aside--open) ~ .l-content .overview {

  &--col-xl-4 {
    .overview__item {
      @include bp-up(xlg){
        width: calc(25% - #{$gutter});
      }
    }
  }

}

.overview__item {
  width: 100%;
  z-index: 0;
  margin-bottom: $gutter;

  @include bp-up(lg){
    margin-left: $gutter / 2;
    margin-right: $gutter / 2;
  }
}

.teaser__image {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }
}

.teaser__image.slick-initialized {
  visibility: visible;
  opacity: 1;
}
