#sort_limit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.sort-select,
.select-wrapper {
  position: relative;
  width: calc(50% - (#{$gutter} / 2));

  label {
    margin-right: 1rem;
    display: none;
  }

  select {
    width: 100%;
    background: none;
    border-radius: .2rem;
    min-width: 10rem;
    position: relative;
    -webkit-appearance: none;
    box-shadow: none;
  }

  i {
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    color: $grey-medium;
    user-select: none;
  }
}

@include bp-up(md) {
  .sort-select,
  .select-wrapper {
    width: fit-content;

    label {
      display: inline-block;
    }

    select {
      width: auto;
    }
  }
}
