.pagination-container {
  width: 100%;
}

ul.caw-pagination {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: ($gutter / 2 - $default-padding / 6);
  margin: $gutter 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    min-width: 3.4rem;
    font-weight: normal;
    color: $grey-dark;
    text-align: center;
    border: 1px solid $border-color;
    border-radius: $default-border-radius;
    padding: $default-padding/4 $default-padding/2;
    margin: 1.5px;
    cursor: pointer;
  }

  a:hover,
  .active a {
    border: 1px solid $blue;
    color: $white;
    background: $blue;
  }
}
