.link {
  @include underlined($blue);
  color: $blue;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $blue;
  }

  &--caw-foldout {
    @include icomoon(icon-chevron-down, after, 1rem);
    &:after {
      transition: transform .3s ease-out;
    }
  }

  &--back {
    @include icomoon(icon-chevron-left, before, 1rem);
  }

  &--action {
    @include underlined($grey-dark);
    color: $grey-dark;

    &:hover {
      color: $grey-dark;
    }
  }


}
