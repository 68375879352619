.autodiskAside {
  $ad-large-text: 1.5em;

  form {
    .form-control {
      font-size: $base-fontsize;
      border: 0;
      padding: 0;

      &:focus,
      &:focus-visible,
      &:active {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: none;
      }

      &[type="range"] {
        border: none;
      }
    }
  }

  .lease-form-label {
    font-size: $ad-large-text;
    font-weight: bold;
  }

  .slider-value {
    float: right;
    font-weight: bold;
  }

  .calculated-price {
    font-weight: bold;
    text-align: right;

    span {
      font-size: $ad-large-text;
    }

    span.calculated-price-monthly {
      font-size: 1em;
      font-weight: normal;
    }
  }

  .price-overview {
    line-height: $ad-large-text;
    margin-bottom: $gutter / 2;
  }

  .btn {
    width: 100%;
  }

  .pull-right {
    float: right;
  }

  .row {
    margin-top: $gutter / 2;
  }
}
