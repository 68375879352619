.form-control {
  &[readonly] {
    color: $grey-dark;
    pointer-events: none;
  }
}

.form-submit {
  &:hover {
    transition: all .15s linear;
  }
}

.form-group {
  &.row {
    margin-top: $gutter/2;
    margin-bottom: $gutter/2;
  }

  .btn {
    width: 100%;

    @include bp-up(sm) {
      width: auto;
    }
  }
}

.col-form-label,
form .col-form-label {
  //align-self: center;
  font-size: 1.6rem;
  font-weight: $font-medium;
  margin-bottom: 0;
}

.form-check-input {
  & + .form-check-label {
    margin-left: ($gutter / 2);
    transition: color .3s ease-out;
  }

  &:checked,
  &:enabled:hover {
    + .form-check-label {
      color: $blue;
      //font-weight: $font-bold;
    }
  }
}

// Radio & Checkbox.
.form-check-input {
  visibility: visible;
  position: absolute;
  opacity: 0;

  & + .form-check-label {
    line-height: 2.6rem;
  }

  &:enabled {
    cursor: pointer;

    & + .form-check-label {
      cursor: pointer;
    }
  }
}

// Radio.
.form-check-input[type='radio'] + label {
  &:before {
    background: $white;
    border: 1px solid $border-color;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 3px;
    left: 0;
    visibility: visible;
    transition: border-color .3s ease-out, background-color .3s ease-out;
  }

  &:after {
    background: $white;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: .8rem;
    width: .8rem;
    left: 6px;
    top: 9px;
    position: absolute;
    visibility: visible;
  }
}

// Radio checked.
.form-check-input[type='radio']:checked + label {
  &:before {
    background: $blue;
  }
}

// Radio hover.
.form-check-input[type='radio']:enabled:hover + label {
  &:before {
    border-color: $blue;
  }
}

// Radio disabled.
.form-check-input[type='radio']:disabled + label {
  &:before {
    border-color: $grey-light;
  }
}

// Checkbox.
.form-check-input[type='checkbox'] + label {
  @include icomoon(icon-check, after, 1.4rem);
  &:before {
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $default-border-radius;
    content: "";
    display: inline-block;
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 3px;
    left: 0;
    visibility: visible;
    z-index: 1;
    transition: all .3s ease-out;
  }

  &:after {
    color: $blue;
    height: 2rem;
    width: 2rem;
    left: 3px;
    top: 6px;
    position: absolute;
    visibility: visible;
    z-index: 2;
    margin: 0;
    transform: scale(0);
    transition: all .3s ease-out;
  }
}

// Checkbox checked.
.form-check-input[type='checkbox']:checked + label {
  &:before {
    background-color: $blue;
    border-color: $blue;
  }

  &:after {
    color: $white;
    transform: scale(1);
  }
}

// Checkbox hover.
.form-check-input[type='checkbox']:enabled:hover + label {
  &:before {
    border-color: $blue;
  }
}

// Checkbox disabled.
.form-check-input[type='checkbox']:disabled + label {
  &:before {
    background-color: $grey-light;
  }
}

button:focus,
input:focus {
  outline: 0;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=date],
input[type=time],
textarea,
textarea.form-control,
.input-group-prepend,
.input-group-append {
  box-sizing: border-box;
  padding: .7rem;
  height: 45px;
  font-family: $font-primary;
  font-size: 1.6rem;
  @include bp-up(lg) {
    font-size: $base-fontsize;
  }
  line-height: 2.4rem;
  height: 4rem;
  width: 100%;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
  border: 1px solid $border-color;
  border-radius: $default-border-radius;
  outline: none;
  -webkit-appearance: none;
  box-shadow: none;
  background-color: $white;

  &:focus {
    border-color: $input-focus;
  }
}

input[type=date] + input[type=time] {
  margin-left: $default-padding;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

textarea,
textarea.form-control {
  height: auto;
}

.input-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.input-group-prepend,
.input-group-append {
  width: auto;
  background-color: $grey-light;
}

.input-group-prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  margin-right: -1px;
}

.input-group-append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  margin-left: -1px;
}

select,
select.form-control {
  height: auto;
  box-sizing: border-box;
  border: 1px solid $border-color;
  padding: $filterpadding $filterpadding*3.5 $filterpadding $filterpadding;
  font-family: $font-primary;
  font-size: $base-fontsize;
  transition: background-color .2s ease-in-out, border-color .2s ease-in-out;
  outline: none;
  -webkit-appearance: none;
  color: #000;
  border-radius: 0;
  position: relative;
  background-color:$white;

  &:focus {
    border-color: $input-focus;
  }

  & + i {
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    color: $grey-medium;
    user-select: none;
  }
}

.select-wrapper {
  position: relative;
}

.form-group .select-wrapper {
  width: 100%;

  select {
    width: 100%;
  }
}

select.form-control {
  height: auto;
}

fieldset.form-group div[id]:not(.form-check) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .form-check {
    flex-basis: 25%;
  }
}

// GDPR fields.
div[id*="_gdpr"] {
  .col-sm-2,
  .col-sm-3 {
    display: none;
  }

  .col-sm-2 + .col-sm-10,
  .col-sm-3 + .col-sm-9 {
    max-width: 100%;

    flex-basis: 100%;
  }
}

div[id$="_gdpr"] {
  > .row {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Submit btn alignment.
form > .row.form-group:last-child,
form > div > .row.form-group:last-child {
  justify-content: flex-end;
}

// Required fields.
.col-form-label.required {
  &:after {
    color: #FF0000;

    padding-left: .5rem;

    content: "*";
  }
}

// Form errors / field validation.
.invalid-feedback {
  display: none !important;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
select,
textarea {
  &:focus:invalid {
    @extend .is-invalid;
  }

  &[placeholder]:invalid:not(:placeholder-shown) {
    @extend .is-invalid;
  }

  &:required:focus:valid:not([value=""]) {
    @extend .is-valid;
  }

  &.is-invalid:valid:not(:placeholder-shown) {
    @extend .is-valid;

    + .invalid-feedback {
      display: none;
    }
  }
}

input[type=radio],
input[type=checkbox] {
  &:focus:invalid {
    ~ label {
      @extend .is-invalid;
      color: theme-color("danger");
    }

    ~ label:before {
      border-color: theme-color("danger");
    }
  }
}

// CAW Mini styling.
form[name="cawmini_filter"] {
  background-color: inherit;

  .caw-form-group {
    margin-bottom: $gutter/2;

    label {
      display: none;
    }

    .form-select {
      @include icomoon(icon-caret-down, after);

      position: relative;

      &:after {
        position: absolute;
        top: $gutter/2;
        right: 1rem;
      }

      select {
        width: 100%;
      }
    }
  }

  .row.caw-mini-submit {
    margin-top: 0;

    .col-sm-3 {
      display: none;
    }

    .col-sm-9 {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }

    button {
      @extend .btn;

      width: 100%;

      &,
      &.btn,
      &.btn-block {
        line-height: 4rem;

        margin-top: 0;
      }
    }
  }

}
