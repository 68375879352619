// Detail wrapper.
.caw-detail {
  margin-top: -1px !important;

  .aside {
    margin-top: $block-padding;

    z-index: 2;

    @include bp-up(lg) {
      margin-top: -1rem;
    }

    .caw-foldout {
      margin-top: $gutter / 2;
    }
  }

  .price .link {
    font-size: $base-fontsize;
  }

  .l-content .caw-detail-nav {
    padding: 0;
  }

  .caw-related {
    padding: $gutter 0;

    h3.related {
      color: $black;
    }

    &:before {
      width: 300vw;
      height: 100%;

      background-color: $blue-lightest;

      position: absolute;
      left: -100vw;
      bottom: 0;
      z-index: -1;

      content: "";
    }

    .teaser {
      background-color: $white;
    }
  }

  .l-content-wrapper {
    margin-bottom: $gutter;
  }

  .caw-finance-table {
    padding-top: $block-padding;
  }
}

// Detail media buttons.
.caw-detail__media {
  padding-bottom: $block-padding;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  align-self: flex-start;

  order: -1;

  @include bp-up(nav) {
    @include make-col(5);

    padding-top: 1rem;

    justify-content: flex-end;
  }

  a {
    font-size: 1.4rem;
    color: $white;
    line-height: 3rem;

    border-radius: $default-border-radius;
    border: 0 solid transparent;

    background-color: $color-primary;

    padding: 0 1em;
    margin: 0 1rem 1rem 0;

    @include bp-up(nav) {
      margin: 0 0 1rem 1rem;
    }

    transition: .3s ease 0s;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Detail intro.
.caw-detail__intro {
  width: 100%;

  color: $detail-header-fontcolor;

  padding: $block-padding 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: stretch;

  &:before {
    width: 300vw;
    height: 100%;

    background-color: $detail-header-background;

    position: absolute;
    left: -100vw;
    bottom: 0;
    z-index: -1;

    content: "";
  }

  > * {
    flex-basis: 100%;
  }

  .caw-detail__title {
    font-size: 2.4rem;
    text-transform: none;
    line-height: 1.2;

    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $block-padding;

    margin: 0;

    order: 0;

    @include bp-up(nav) {
      @include make-col(7);

      font-size: 4.2rem;
      text-transform: none;
      line-height: 1.2;

      margin: 0;

      order: -2;
    }
  }

  .caw-detail__subtitle {
    padding-bottom: $block-padding;

    @include bp-up(lg) {
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  .caw-detail__highlights {
    font-size: 1.3rem;
    line-height: 1.5rem;

    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    > * {
      list-style: none;

      border-radius: .5rem;

      background-color: #EEEEEE;

      padding: .5rem 1.2rem;

      margin: 0 1em 1em 0;

      position: relative;

      @include bp-up(lg) {
        width: auto;
      }
    }

    > .vehicle-type {
      color: #EEEEEE;
      background-color: $grey-dark;
    }
  }
}

// Quick links.
.caw-detail__quicklinks {
  padding: 0 0 1.5rem 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: flex-end;

  position: relative;

  &:before {
    width: 300vw;
    height: 100%;

    background-color: $white;

    position: absolute;
    left: -100vw;
    bottom: 0;
    z-index: -1;

    content: "";
  }

  .item {
    margin: 0 $gutter 0 0;

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin: 0;
    }
  }
}

// Teaser specs.
.caw-detail__teaser-specs {
  padding: ($gutter / 2) 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  align-content: stretch;

  position: relative;

  @include bp-up(lg) {
    padding: 0;
  }

  &:before {
    width: 300vw;
    height: 100%;

    background-color: $blue-lightest;

    position: absolute;
    left: -100vw;
    bottom: 0;

    content: "";
  }

  .item {
    color: $grey-dark;
    line-height: 1.6rem;

    margin: ($gutter / 2) 0;

    flex-basis: 50%;

    z-index: 1;

    i {
      font-size: 3rem;

      margin-right: 1rem;

      float: left;
    }

    strong {
      display: block;
    }

    @include bp-up(lg) {
      padding: ($gutter / 2);
      margin: $gutter 0;

      flex-basis: 25%;

      position: relative;

      &:after {
        width: 1px;
        height: 100%;

        background-color: $grey-light;

        position: absolute;
        top: 0;
        right: 0;

        content: "";
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }
}

// Detail navigation.
.caw-detail-nav {
  display: none;
  position: relative;

  @include bp-up(lg) {
    display: block;
  }

  &:before {
    width: 300vw;
    height: 100%;

    background-color: $blue;

    position: absolute;
    left: -100vw;
    bottom: 0;

    content: "";
  }

  ul,
  li {
    list-style: none;

    padding: 0;
    margin: 0;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    position: relative;
    z-index: 1;
  }

  .nav__item {
    font-size: 1.6rem;
    color: $white;
    text-decoration: none;
    line-height: 5rem;

    padding: 0 $gutter;

    display: block;

    transition: background-color .3s ease-out;

    &:hover,
    &--active {
      background: $blue-dark;
    }
  }
}

// List / detail toggle buttons.
.caw-detail {

  .is-hidden {
    display: none;
  }

  .is-visible {
    display: block;
  }

  .description__intro:not(.is-hidden),
  .description__full:not(.is-hidden) {
    display: inline;
  }

  .description__toggle {
    width: 100%;

    display: block;
    float: left;
  }

  .list-make-visible-toggle {
    width: 100%;

    white-space: nowrap;

    background-color: $white;

    padding-top: 1.5rem;

    margin-top: - 1.5rem;

    position: relative;
    display: block;

    cursor: pointer;

    &,
    &:hover,
    &:active {
      color: $blue;
      text-decoration: none;

      border: none;
    }

    &.active {
      margin-top: 1.2rem;
    }

    &,
    &:after,
    > *{
      transition: all .3s ease;
    }

    &:after {
      height: 6rem;

      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);

      position: absolute;
      top: -6rem;
      left: 0;
      right: 0;

      pointer-events: none;

      content: "";
    }

    &.active:after {
      height: 0;

      top: 0;
    }

    > * {
      @include icomoon(icon-chevron-down, after);

      border-bottom: 1px solid transparent;

      position: relative;
      float: left;

      &:after {
        line-height: inherit;
        position: absolute;
        transition: transform .3s ease;
        transform-origin: center center;
      }

      &.list-make-hide {
        &:after {
          top: 1px;
          transform: rotate(180deg);
        }
      }
    }

    > *:hover,
    &:hover > * {
      border-bottom-color: $blue;
    }
  }

}

.caw-detail {
  .description {
    display: inline-block;

    overflow: hidden;

    transition: max-height .3s ease;

    &.is-teaser {
      max-height: 14.4rem; // 144px;
    }

    &.is-full {
      max-height: none;
    }

    p {
      font-weight: inherit;
      line-height: inherit;
    }

    ul,
    ol {
      padding: 0 0 0 1.6rem;
      margin: 1rem 0;
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .description__toggle {
    &.hidden {
      display: none;
    }
  }

  .description-make-visible-toggle {
    @extend .list-make-visible-toggle;

    > .description-make-hide {
      @extend .list-make-hide;
    }
  }
}

// caw-foldout overrides.
.caw-detail {
  .l-content {
    .caw-foldout {
      &,
      .caw-foldout__content {
        width:100%;

        float: left;
      }
    }
  }
}

@include bp-up(lg) {
  .l-content {
    .caw-foldout,
    .caw-foldout--open,
    .caw-foldout--closed {
      .caw-foldout__trigger {
        &:after {
          display: none;
        }
      }

      .caw-foldout__content {
        height: auto !important;

        padding: ($gutter / 2) 0 !important;

        display: block !important;
      }
    }
  }
}
