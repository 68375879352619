.mobile-nav {
  background-color: $blue-lightest;
  display: flex;
  padding-bottom: $gutter/2;
  padding-top: $gutter/2;

  a {
    font-size: 1.6rem;
    font-weight: $font-bold;
    border: 1px solid $color-primary;
    border-radius: $default-border-radius;
    color: $white;
    line-height: 4rem;
    padding: 0 2rem 0 4rem;
    background-color: $color-primary;
    position: relative;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }

    .icon {
      font-weight: normal;
      position: absolute;
      top: .9rem;
      left: 1rem;

      &:before {
        font-size: 2rem;
        margin: 0;
      }
    }
  }

  a.open-filter,
  a.reset-filter {
    &:hover {
      cursor: pointer;
    }
  }

  a.close-filter,
  a.reset-filter{
    color: $grey-dark;
    border-color: transparent;
    background-color: transparent;
    padding: 0 4rem 0 2rem;
    display: none;
    opacity: 0;
    transition: opacity .3s ease-out;

    &:hover {
      cursor: pointer;
    }

    .icon {
      left: auto;
      right: 1rem;
    }
  }

  @include bp-up(lg) {
    display: none;
  }
}

.mobile--filter--open .mobile-nav {
  border-bottom: 2px solid $border-color;
  margin-bottom: $gutter/2;

  a.close-filter,
  a.reset-filter{
    display: block;
    opacity: 1;
  }
}
