.price {
  &--discount {
    font-size: 1.6rem;
    text-decoration: line-through;
  }

  &--current {
    font-size: 2.8rem;
    font-weight: $font-bold;
    margin-top: 0.6rem;
    margin-bottom: 0.5rem;
  }

  &__vat {
    font-size: 1.3rem;
    font-weight: $font-light;
    margin-left: 0.8rem;
    margin-bottom: -0.5rem;
    display: inline-block;
  }

  &--new {
    font-size: 1.6rem;
    font-weight: $font-light;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    line-height: 2rem;
  }

  &--info {
    font-size: 1.6rem;
    font-weight: $font-light;
    margin-top: 0.3rem;
    line-height: 2rem;
    .caw-offer-finance {
      color: #0374b2;
      font-size: 0.8em;
      font-weight: 400;
      float: right;
      text-decoration: none;
    }
    .glkg-banner {
      margin-top: 10px;
      padding: 0;
      border: none;
      margin-bottom: 10px;
    }
  }
}

.price--lg {
  &.price--discount {
    font-size: 1.6rem;
  }

  &.price--current {
    font-size: 4rem;
    margin-bottom: 1.1rem;
  }

  .price__vat {
    font-size: 1.6rem;
    margin-bottom: -1.1rem;
  }
}
