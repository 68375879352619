// Import fonts.
// We use an extra partial to import fonts .
// This way we can import all fonts outside the CAW wrapper!

// Google fonts.
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');

// Icon fonts.
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.ttf?gf9dmb') format('truetype'),
  url('../fonts/icomoon.woff?gf9dmb') format('woff'),
  url('../fonts/icomoon.svg?gf9dmb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
