.meta__item {
  display: inline-block;
  font-weight: $font-light;

  + .meta__item {
    &:before {
      content: "\00a0 \007c \00a0";
    }
  }
}
