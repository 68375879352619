#app-wrapper.caw {
  // CAW - resets.
  @import "base/resets";
}

// CAW - fonts.
@import "abstracts/fonts";

// Import styling.
// Wrap all styling with the CAW wrapper.
#app-wrapper.caw,
#caw-container.caw-container--mini {
  // Bootstrap - required.
  @import "node_modules/bootstrap/scss/functions";
  @import "node_modules/bootstrap/scss/variables";
  @import "node_modules/bootstrap/scss/mixins";

  // Bootstrap - optional.
  @import "node_modules/bootstrap/scss/reboot";
  @import "node_modules/bootstrap/scss/grid";
  @import "node_modules/bootstrap/scss/images";
  @import "node_modules/bootstrap/scss/forms";
  @import "node_modules/bootstrap/scss/utilities/flex";
  @import "node_modules/bootstrap/scss/utilities/spacing";

  // CAW - abstracts.
  @import "abstracts/icomoon";
  @import "abstracts/vars";
  @import "abstracts/mixins";

  // CAW - basics.
  @import "base/base";
  @import "base/icons";

  // CAW - styling.
  @import "base/elements";
  @import "layout/layout";
  @import "layout/header";
  @import "layout/forms";
  @import "layout/aside";

  @import "components/alert";
  @import "components/btn";
  @import "components/card";
  @import "components/checklist";
  @import "components/documents";
  @import "components/filters";
  @import "components/foldout";
  @import "components/horizontal-menu";
  @import "components/licenseplate";
  @import "components/link";
  @import "components/meta";
  @import "components/mobilemenu";
  @import "components/mobile-nav";
  @import "components/overview";
  @import "components/price";
  @import "components/search";
  @import "components/sort";
  @import "components/teaser";
  @import "components/table";
  @import "components/pager";
  @import "components/selection";
  @import "components/spinner";

  @import "components/slider";
  @import "components/warranty";

  @import "pages/detail";
  @import "detail/header";
  @import "detail/gallery";
  @import "detail/autodisk";

  // Custom - operational lease form
  // @import "custom/form_operational_lease";
}

#caw-container {
  // Slick - all.
  @import "node_modules/slick-carousel/slick/slick.scss";
}

// @Todo: move this to the appropriate file.
  #caw-container.filter--open {
    .teaser {
      .btn {
        z-index: -1;
      }
    }
  }

// @Todo: move this to the appropriate file.
  /* Safari! */
  html.is-ios,
  html.is-safari {
    &.caw--mobile--filter--open {
      height: auto;
      min-height: 100%;

      overflow: hidden;
    }

    &.caw-gallery--open,
    &.caw--mobile--buttons,
    &.caw--mobile--filter--open {
      height: 100%;

      body {
        min-height: 100%;
        overflow-y: scroll;
      }
    }

    &.caw--mobile--filter--open,
    &.caw--mobile--buttons:not(.caw-gallery--open) {
      body {
        height: 100%;
      }
    }
  }

  html.is-ios.is-safari {
    &.caw--mobile--filter--open {
      position: fixed;
    }
  }

// @Todo: move this to the appropriate file.
  /* Modals */
  .modal-backdrop.fade {
    opacity: .5;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
