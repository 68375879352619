$noiui-dim: #ddd;
$noiui-highlight: #000000;


/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}

.noUi-stacking + .noUi-origin {
  /* Fix stacking order in IE7, which incorrectly
     creates a new context for the origins. */
  *z-index: -1;
}

.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  width: 39px;
  height: 39px;
  top: -18px;

  &-lower {
    left: -13px;
  }

  &-upper {
    left: -13px;
  }
}

/* Styling;
 */
.noUi-background {

  &[data-start*=","] {
    background: $noiui-dim !important;
  }

  background: $noiui-highlight !important;

  .noUi-background {
    background: $noiui-dim !important;
    margin-right: -13px;
  }
}

.noUi-connect {
  transition: background 450ms;
}

.noUi-origin {
  background: $noiui-dim !important;
  margin-right: -13px;
}

.noUi-origin.noUi-connect {
  background: $noiui-highlight !important;
}

.noUi-target {
  margin: 18px 0;
  padding: 0 13px 0 0;
}

.noUi-target.noUi-connect {
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}

.noUi-handle {
  background: transparent !important;
  cursor: pointer;

  padding: 13px;

  &:after {
    background: $noiui-highlight;
    border-radius: 50%;
    content: "";
    display: block;
    width: 13px;
    height: 13px;
  }
}

.noUi-active {

}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled] .noUi-handle {
  cursor: not-allowed;
}


/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  font: 400 12px Arial;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  width: 40px;
  position: absolute;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 3px 0;
  height: 50px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  margin-left: -20px;
  padding-top: 20px;
}

.noUi-value-horizontal.noUi-value-sub {
  padding-top: 15px;
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 11px;
}

// Geld lenen kost geld banner
.glkg-banner {
  background-color: #fff;
  border: 1px solid #000;
  padding: 10px;
  margin-bottom: 20px;

  img {
    margin: 0 auto;
    float: none;
    display:block;
  }
}

// Finance calculator
.financial-calculator {
  display: none;
}
.financial-calculator, .financiallease, .operationallease {
  margin-top: 20px;

  .vehicle-price {
    label {
      font-weight: 100 !important;
      padding-bottom: 25px !important;
    }
    @media (min-width:576px) {
      .text-align-right {
        font-size: 24px;
        text-align: right;
      }
    }
  }
  .form-group,{
    label {
      display: block;
      padding-bottom: 3px !important;
      font-weight: 100 !important;
      padding-left: 0 !important;
    }

    button[disabled], input[disabled], button[readonly], input[readonly] {
      background: transparent;
      border: none;
      padding: 0;
    }

    input {
      &.financial-price {
        //color: $color-primary;
        font-family: $font-primary;
        font-size: 28px;
      }
    }
  }

  .price-per-month {
    background: $grey-light;
    font-family: $black;
    text-align: right;
    font-size: 15px;
    padding: 20px;
    line-height: 22px;

    .euro-container {
      display: inline-block;
      font-size: 23px;
      padding-left: 10px;
      vertical-align: text-bottom;
    }
  }
}

.financiallease, .operationallease {
  .caw-form-group .noUi-target {
    background-color: #3a3a3a;
  }
}

// Finance preview table
.caw-finance-table {
  background: #fff;
  border: 1px solid #e3e4e8;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 20px;

  .margin {
    margin: 10px 10px 0 10px;

    &[class*="col-"] {
      margin: 10px 0 0 0;
    }
  }
}

.financiallease {
  margin-bottom: 20px;
}