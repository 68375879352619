// Teaser styling.
.teaser {

  @include bp-up(lg) {
    height: 100%;
  }

  border: 1px solid $border-color;

  box-shadow: 0 0 1rem rgba($grey-dark, 0);

  position: relative;
  z-index: 1;

  overflow: hidden;

  transition: all .3s ease-out;

  &:hover {
    box-shadow: 0 0 1rem rgba($grey-dark, 0.2);
  }

  &,
  > * {
    width: 100%;
  }

  &,
  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
  }

  &__image {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: $block-padding;

    position: relative;

    @include bp-up(lg) {
      position: static;
    }

    flex-grow: 1;

    > * {
      margin: 0;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: $font-bold;
  }

  &__action {
    font-size: 1.2rem;
    font-weight: $font-regular;
    color: $blue;
    text-align: center;
    line-height: 2.4rem;

    border-radius: $default-border-radius;

    background: $blue-light;

    padding: 0 $default-padding;

    margin: 1rem 0;
  }

  &__vehicle-type {
    @extend .teaser__action;
    color: $grey-dark;
    background-color: $grey-light;
  }

  &__descr {
    flex: 0 0 auto;
    word-break: break-word;
  }

  &__location {
    font-weight: $font-medium;
    padding-top: .8rem;

    .icon-map-marker {
      @include icomoon(icon-map-marker, before, 1.6rem);
      color: $color-primary;
      &:before {
        margin-right: .3rem;
      }
    }
  }

  .price {
    margin-top: auto;

    & + .price {
      margin-top: .6rem;
    }

    & + .price:before {
      display: none;
    }
  }

  .teaser__vehicle-type + .teaser__action {
    margin-top: 0;
  }

  div:not(.teaser__action) + .price:before {
    width: 100%;
    height: 1em;

    float: left;

    content: "";
  }

  .btn {
    min-width: 100%;

    margin-top: 1rem;

    position: static;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;

      content: "";
    }
    
    &:after {
      margin-right: -2rem;

      position: relative;
      right: 0;
      float: right;
    }
  }

  &--noborder {
    border: 0;

    &:hover {
      box-shadow: none;
    }
  }

  &--nobutton {
    .btn {
      display: none;
    }
  }
}

// Sidebar teaser styling.
.aside--right .teaser {
  width: auto;
  min-width: 100%;

  margin: - ($gutter / 2);
  margin-bottom: 0;

  @include bp-up(lg) {
    margin-top: - $gutter;
  }

  &__content {
    padding: 1.5rem 3rem 1.5rem;
  }
}
