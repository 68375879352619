// Breakpoints.
$breakpoints: (
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xlg': 1200px,
        'nav': 1000px,
) !default;

$gutter:                    3rem;
$block-padding:             $gutter / 2;

$max-width:                 1180px;

// Colors.
$blue:                      #0374B2;
$blue-dark:                 #135A84;
$blue-medium:               #B5CDDA;
$blue-light:                #E5F1F7;
$blue-lightest:             #F6F8FB;

$grey-light:                #EBEBEB;
$grey-medium:               #818183;
$grey-dark:                 #4A4A4A;

$orange:                    #F5A623;
$orange-dark:               #E7852E;

$white:                     #FFFFFF;
$black:                     #000000;
$green:                     #029a16;
$yellow:                    #ffbf02;
$blue-other:                #0375d9;

$whatsapp:                  #28D146;

// Primary + Secondary colors

$color-primary:             $blue;
$color-secondary:           $orange;


// Element colors
$border-color:              #DDDDDD;
$input-focus:               $color-primary;


// Typography.
$font-primary:              'Roboto', sans-serif;
$font-icons:                'Font Awesome 5 Free', sans-serif;

$base-fontsize:             1.4rem;
$base-lineheight:           2.4rem;

$default-border-radius:     .3rem;
$default-padding:           $base-fontsize;

$font-light:                300;
$font-regular:              400;
$font-medium:               500;
$font-bold:                 700;
$font-black:                900;

// Elements

$filterpadding:             $default-padding/2;
$checkboxsize:              2rem;
$mobilemenu-height:         6rem;

// License plate coloring (NL)
$lp-primary-bg:             $yellow;
$lp-primary-color:          $grey-dark;
$lp-secondary-bg:           $blue-other;
$lp-secondary-color:        $white;

// Detail settings
$detail-image-background:   #222222;
$detail-header-background:  #FFFFFF;
$detail-header-fontcolor:   #4A4A4A;

