.checklist {
  background-color: $blue-lightest;
  list-style: none;
  padding: 2rem;
  margin: 0;

  li {
    @include icomoon(icon-check-circle, before, 1.8rem);
    font-weight: $font-light;
    line-height: 2rem;
    padding: .5rem 0 .5rem 3rem;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      color: $green;
    }
  }
}