// BASIC FILTER STYLING.
.filters {
  @extend %reset-list;

  .reset-filters {
    > div {
      border: none !important;
      padding-top: 6px;

      &:hover {
        background: none !important;
        color: $black;
      }
    }
  }

  .filter {
    margin-bottom: $default-padding;
    border-bottom: 1px solid $border-color;
    cursor: pointer;

    &__label {
      color: $grey-medium;
      line-height: 2rem;
      margin: 0;
      padding-top: $filterpadding;
      padding-bottom: $filterpadding/2;
      white-space: nowrap;
    }

    &__type {
      @include icomoon(icon-chevron-down, after);
      font-size: 1.6rem;
      font-weight: $font-bold;
      position: relative;
      margin-bottom: $default-padding;

      &:after {
        line-height: inherit;
        position: absolute;
        top: 0;
        right: 0;
        transition: transform 0.3s ease;
      }
    }

    &__items {
      padding-bottom: $default-padding;
      padding-left: 0;

      &--checkboxes {
        padding-bottom: 0;

        &:last-of-type {
          padding-bottom: $default-padding;
        }
      }

      &--showmore {
        display: none;
      }
    }

    &__items,
    .filter__items--select {
      display: none;
    }

    &--open {
      .filter__type {
        &:after {
          transform: rotate(180deg);
        }
      }

      .filter__items {
        display: block;
      }

      .filter__items--select {
        display: flex;

        .filter__item {
          width: 45%;

          &:first-of-type {
            margin-right: 10%;
          }
        }
      }
    }

    &:first-of-type {
      .filter__items {
        &--showmore {
          display: none;
        }
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    .link--caw-foldout {
      margin: $default-padding 0;
    }
  }

  #filter-make-visible-toggle {
    white-space: nowrap;
    margin-top: $default-padding;

    &,
    &:hover,
    &:active {
      color: $blue;
    }

    > * {
      @include icomoon(icon-chevron-down, after);

      position: relative;

      &:after {
        line-height: inherit;
        position: absolute;
        transition: transform 0.3s ease;
        transform-origin: center center;
      }

      &.open,
      &.active {
        &:after {
          top: 1px;
          transform: rotate(180deg);
        }
      }

      &#filter-make-hide,
      &[id="filter-make-hide"] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .is-hidden,
  .hide {
    display: none !important;
  }

  .is-visible {
    display: block;
  }
}

// CHECKBOX SPECIFIC.
.filter__items--checkboxes {
  .filter__label {
    position: relative;
    display: block;
    padding-left: 1.2 + $checkboxsize;
    cursor: pointer;
  }
}

// CHECKBOXES STYLING.
.filter__checkbox {
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:checked {
    & ~ .filter__label {
      font-weight: $font-bold;
      color: $grey-dark;

      .filter__label__results {
        font-weight: normal;
      }

      .filter__checkbox-custom {
        border-color: $blue;
        background-color: $blue;

        .icon {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.filter__checkbox-custom {
  border: 1px solid $border-color;
  background-color: $white;
  border-radius: $default-border-radius;
  display: block;
  transition: all 0.3s ease-out;
  position: absolute;
  top: $filterpadding;
  left: 0;
  padding: 2px;
  width: $checkboxsize;
  height: $checkboxsize;

  .icon {
    color: white;
    font-size: 1.4rem;
    transform: scale(0);
    transition: all 0.3s ease-out;
    position: absolute;
  }
}

// CHECKBOX SUB.
.filter__items--sub {
  margin: 0;
  padding: 0;

  .filter__item {
    border-left: 1px dashed $blue-medium;
    padding-left: 24px;
    margin-left: $checkboxsize/2; // is half of checkbox width
    position: relative;

    &:before {
      content: "";
      border-bottom: 1px dashed $blue-medium;
      display: block;
      position: absolute;
      width: 24px;
      left: 0;
      top: $checkboxsize/2 + $filterpadding; // half of checkbox height + padding between items
    }

    &:last-of-type {
      &:after {
        content: "";
        background-color: white;
        display: block;
        position: absolute;
        width: 2px;
        left: -2px;
        top: $checkboxsize/2 + $filterpadding;
        height: $checkboxsize/2 + $filterpadding;
      }
    }
  }
}

// SELECT SPECIFIC.
.filter__items--select {
  padding-right: 12px;
  display: flex;

  .filter__label {
    color: $grey-dark;
    display: block;
    font-weight: $font-bold;
    margin-bottom: $filterpadding;
  }

  .filter__item {
    margin-bottom: $filterpadding * 2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

// SELECT STYLING.
.filter__items--select {
  .select-wrapper {
    width: auto;
  }

  select {
    width: 100%;
    box-shadow: none;
  }
}

// DEVICE WIDTH SPECIFIC.

// TABLET/DESKTOP.
@include bp-up(nav) {
  .filters {
    // Filters in top region.
    &--top {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;

      position: relative;
      z-index: 1;

      &:after {
        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 1);

        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;

        opacity: 0;

        visibility: hidden;

        transition: opacity 0.3s ease 0.1s, visibility 0.1s ease 0.3s;

        content: "";
      }

      &.filter--open:after {
        // opacity: .7;

        // visibility: visible;

        // transition: opacity .3s ease;
      }

      > *,
      .filter {
        border: none;

        margin-bottom: 0px;
      }

      > * {
        z-index: -1;
      }

      .filter {
        position: relative;
        z-index: 3;

        &__type {
          min-height: 3.3rem;
          font-weight: normal;
          border: 1px solid $border-color;
          border-radius: $default-border-radius;
          padding: $default-padding/4 $default-padding/2;
          margin-right: $default-padding/4;
          margin-bottom: 0;

          &:after {
            display: none;
          }
          &--active,
          &--checked,
          &:hover {
            border: 1px solid $blue;
            color: white;
            background: $blue;
            cursor: pointer;
          }
        }

        &__items {
          background-color: white;
          box-shadow: 0 0 1.3rem rgba($grey-dark, 0.1);
          padding: 7px 40px 14px 12px;
          position: absolute;
          z-index: 1;
        }
      }
    }

    // Filters in sidebar region.
    &-aside,
    &--aside {
      .filter__type + .filter__items {
        //margin-top: - $default-padding;
        margin-top: -1rem;
      }
    }
  }
}

.filter-make-model.filter--open {
      .filter__items {
        @include bp-up(nav) {
          max-height: 70vh;
          overflow-y: scroll;
        }
      }
}
