.aside {
  &--right {
    .inner {
      padding: $gutter/2;
    }
  }
}

@include bp-up(lg) {
  .caw-detail {
    .aside {
      top: 0;
      align-self: flex-start; // This enables sticky behaviour for flex elements.

      transition: all 0s ease 0s; // This enables smooth scrolling for JS scrolling elements.
    }
  }

  .aside {
    &--right {
      .inner {
        background: $white;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
        padding-top: $gutter;
      }
    }
  }

}
