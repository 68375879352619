.l-search-wrapper {
  padding: 1.2rem 0;

  @include bp-up(lg) {
    .search__input {
      height: 3.2rem;
    }
  }
}

.search {
  background: $white;
  min-width: 20rem;
  position: relative;
  margin-top: $gutter;

  @include bp-up(lg) {
    margin-top: 0;
  }

  &:focus-within {
    outline: 1px solid $blue;
    box-shadow: 0 0 4px $blue;
  }

  &__input {
    border: 0;
    color: $grey-medium;
    font-size: $base-fontsize;
    padding: .7rem;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $grey-dark;
      font-weight: 300;
      font-style: italic;
      font-size: $base-fontsize;
    }
  }

  i {
    color: $grey-dark;
    position: absolute;
    right: 1.2rem;
    top: 1.3rem;
    @include bp-up(lg) {
      top: 1rem;
    }
  }

  .form-submit {
    position: absolute;
    top: 0;
    right: 0;
    z-index: +1;
    bottom: 0;
    width: 5rem;
    background: none;
    color: transparent;
    border: none;
    cursor: pointer;
  }
}


#caw-container.caw-results-height {
  .l-content-wrapper .l-content {
    min-height: 1000px;
  }
}
