[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-steering-wheel {
  content: "\e900";
}
.icon-search {
  content: "\f002";
}
.icon-heart {
  content: "\f004";
}
.icon-check {
  content: "\f00c";
}
.icon-file-o {
  content: "\f016";
}
.icon-download {
  content: "\f019";
}
.icon-eye {
  content: "\f06e";
}
.icon-play-circle-o {
  content: "\f01d";
}
.icon-image {
  content: "\f03e";
}
.icon-photo {
  content: "\f03e";
}
.icon-picture-o {
  content: "\f03e";
}
.icon-map-marker {
  content: "\f041";
}
.icon-chevron-left {
  content: "\f053";
}
.icon-chevron-right {
  content: "\f054";
}
.icon-check-circle {
  content: "\f058";
}
.icon-calendar {
  content: "\f073";
}
.icon-chevron-up {
  content: "\f077";
}
.icon-chevron-down {
  content: "\f078";
}
.icon-heart-o {
  content: "\f08a";
}
.icon-phone {
  content: "\f095";
}
.icon-caret-down {
  content: "\f0d7";
}
.icon-caret-up {
  content: "\f0d8";
}
.icon-caret-left {
  content: "\f0d9";
}
.icon-caret-right {
  content: "\f0da";
}
.icon-envelope {
  content: "\f0e0";
}
.icon-rotate-left {
  content: "\f0e2";
}
.icon-undo {
  content: "\f0e2";
}
.icon-share-alt {
  content: "\f1e0";
}
.icon-whatsapp {
  content: "\f232";
}
.icon-more-vertical {
  content: "\e901";
}
.icon-close {
  content: "\f00d";
  //content: "\e0fd";
}
.icon-sort-amount-desc {
  content: "\e903";
}
.icon-sort-amount-asc {
  content: "\e904";
}
.icon-filter {
  content: "\e902";
}
.icon-brandstof {
  content: "\e911";
}
.icon-elektrisch {
  content: "\e905";
}
.icon-transmissie {
  content: "\e906";
}
.icon-speedometer {
  content: "\e907";
}
.icon-trash {
  content: "\f014";
}

@mixin icomoon($icon, $position: "before", $fontsize: $base-fontsize) {
  &:#{$position} {
    @extend .#{$icon};
    @content;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: $fontsize;

    @if $position == "before" {
      margin-right: 0.5em;
    }

    @if $position == "after" {
      margin-left: 0.5em;
    }
  }
}