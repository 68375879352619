.l-content-top,
.l-content {
  transition: all .3s ease-out;
}

.l-sorting-wrapper {
  margin-bottom: $gutter/2;
}

.l-content-top {
  @include bp-up(lg) {
    display: flex;
    order: -1;

    .l-sorting-wrapper {
      margin-left: auto;
      margin-bottom: 0;

      .sort-select {
        margin-top: $gutter/2;
        margin-left: $default-padding;
        float: left;
      }
    }
  }
}

.caw-results {
  .l-content-wrapper {
    overflow: hidden;
    flex-wrap: wrap;
  }
}

.l-content {
  @include bp-up(lg) {
    justify-content: flex-end;
    order: 1;
  }
}

.filters.filters--top {
  > .open-aside,
  > .reset-filters {
    display: none;

    @include bp-up(lg) {
      display: inherit;
    }
  }

  > .reset-filters {
    &:hover {
      text-decoration: underline;
    }
  }
}

.aside--left {
  background-color: $blue-lightest;
  padding-top: 0;
  overflow: hidden;
  transition: margin .3s ease-out;

  > .filter-wrapper,
  > .filters__close {
    display: none;
  }
}

.aside--left {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .3s ease-out;
}

.mobile--filter--open {
  .aside--left {
    height: 100%;
    padding-bottom: 10rem;
    opacity: 1;
  }

  .l-content-wrapper > *:not(.aside--left) {
    height: 0;
  }
}

@include bp-up(lg) {
  .aside--left,
  .l-content-wrapper > *:not(.aside--left),
  .mobile--filter--open .l-content-wrapper > *:not(.aside--left) {
    height: 100%;
    background-color: inherit;
    opacity: 1;
  }

  .aside--left {
    padding: 0 !important;
    margin-left: 0 !important;
    /* Removed margin bottom. Boris thinks this had a reason. Needs testing. */
    /* margin-bottom: -100%; */
    height: 0;
    margin: 0;
    /* 2 lines above added in favor of the margin-bottom: -100% */
    flex-basis: 0 !important;
    transition: margin .3s ease-out, flex-basis .3s ease-out, height .3s ease-out .3s;

    > * {
      border-top: 1px solid $border-color;
      padding: ($gutter / 2) 0;
      margin: 0 $gutter 0 ($gutter / 2);
    }

    &.aside--open {
      margin-left: 0;

      @include bp-up(lg) {
        flex-basis: 25% !important;
        /* This line (below) added after adding height:0  on line 104. */
        height: auto;
      }
    }
  }
}
