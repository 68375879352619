.licenseplate {
  background-color: $lp-primary-bg;
  border-radius: $default-border-radius;
  color: $lp-primary-color;
  display: flex;
  overflow: hidden;
  font-size: 1.4rem;
  line-height: 2.4rem;
  width: fit-content;

  &__country {
    background-color: $lp-secondary-bg;
    color: $lp-secondary-color;
    padding: 0 .4rem;
  }

  &__code {
    letter-spacing: .1rem;
    padding: 0 1rem;
  }
}
