.btn {
  @include icomoon(icon-chevron-right, after, 1rem);
  background-color: $color-secondary;
  border-radius: 0.5rem;
  box-shadow: none;
  border: 0;
  color: white;
  cursor: pointer;
  display: block;
  //line-height: 5.4rem;
  line-height: 4.4rem;
  font-size: 1.6rem;
  font-weight: $font-bold;
  padding: 0 4rem 0 2rem;
  position: relative;
  transition: all 0.3s ease-out;
  margin-top: $gutter/2;
  text-decoration: none;
  text-transform: uppercase;

  &:after {
    line-height: inherit;
    position: absolute;
    right: 2rem;
  }

  &:hover {
    background-color: $orange-dark;
    color: $white;
    text-decoration: none;
  }

  &--outline {
    background-color: transparent;
    border: 2px solid $color-secondary;
    color: $color-secondary;
    line-height: 4rem;

    &:after {
      color: $color-secondary;
    }

    &:hover {
      background-color: transparent;
      border: 2px solid $orange-dark;
      color: $orange-dark;

      &:after {
        color: $orange-dark;
      }
    }
  }

  &[disabled] {
    opacity: .35;
    pointer-events: none;
  }

  &--link {
    background: transparent;
    border: none;
    font-weight: normal;
    text-transform: initial;
    margin-right: auto;
    width: auto;

    &:after {
      right: auto;
      left: 5px;
    }
  }
}

form button[type="submit"].btn-primary {
  &:after {
    line-height: inherit;
    position: absolute;
    right: 2rem;
    content: "\f054" !important;
  }
}

.btn.filters-aside__button {
  margin-top: $gutter;
  margin-bottom: $gutter/2;
  position: fixed;
  bottom: 0;
  transition: all 0.3s ease-out, bottom 0s;
}

@include bp-up(lg) {
  .btn.filters-aside__button {
    display: none;
  }
}

.caw-cta-whatsapp,
.aside--right a.btn.caw-cta-whatsapp {
  &,
  &:hover {
    &,
    &.btn--outline {
      color: $white !important;
      border: 2px solid $whatsapp !important;
      background-color: $whatsapp !important;
    }
  }
}
